export const adjective = {
	singular: "clinic",
	plural: "clinics",
};

export const verb = {
	singular: "treating",
};

export const job = {
	singular: "appointment",
	plural: "appointments",
};
